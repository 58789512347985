import imagesLoaded from 'imagesloaded';
import $ from 'jquery';
import Masonry from 'masonry-layout';

$(function () {
	var grids = document.querySelectorAll('.masonry-grid');
	if (grids) {
		grids.forEach((grid) => {
			var msnry = new Masonry(grid, {
				// options...
				itemSelector: '.newsCard', // width 23.5%
				columnWidth: '.newsCard', // width 23.5%
				percentPosition: true,
				gutter: '.gutter-sizer',
			});
			$(grid).data('masonry', msnry);
			imagesLoaded(grid, () => msnry.layout());
		});
	}
});
