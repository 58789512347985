import jQuery from 'jquery';

jQuery(function ($) {
	// 	function videoResizer() {
	// 		if ($('.video-container')) {
	// 			// Selector for the container element
	// 			const $container = $('.video-container');
	// 			const $blockContainer = $(
	// 				'.panel__heroBanner.-background > .block__heroBanner',
	// 			);
	// 			const $halfBlockContainer = $('.block__halfHeroBanner');
	// 			// Selector for the iframe element
	// 			const $video = $('.heroVideo');
	// 			// Find the dimensions of the container
	// 			const containerWidth = $container.width();
	// 			const containerHeight = $container.height();
	// 			// Get the aspect ratio of the container div
	// 			const containerAspectRatio = containerWidth / containerHeight;
	// 			const videoAspectRatio = 16 / 9;
	// 			// Checking if the video is too wide
	// 			if (videoAspectRatio > containerAspectRatio) {
	// 				const videoHeight = containerHeight;
	// 				const videoWidth = videoHeight * videoAspectRatio;
	// 				const videoLeft = -(videoWidth - containerWidth) / 2;
	// 				$video.css({
	// 					top: '0px',
	// 					left: videoLeft + 'px',
	// 					width: videoWidth,
	// 					height: videoHeight,
	// 				});
	// 			} else if (videoAspectRatio < containerAspectRatio) {
	// 				const videoWidth = containerWidth;
	// 				const videoHeight = videoWidth / videoAspectRatio;
	// 				const videoTop = -(videoHeight - containerHeight) / 2;
	// 				$video.css({
	// 					top: videoTop + 'px',
	// 					left: '0px',
	// 					width: videoWidth,
	// 					height: videoHeight,
	// 				});
	// 			} else {
	// 				$video.css({
	// 					top: '0px',
	// 					left: '0px',
	// 					width: containerWidth,
	// 					height: containerHeight,
	// 				});
	// 			}
	// 			if (window.matchMedia('(max-width: 1023px)').matches) {
	// 				$video.css({
	// 					top: '0px',
	// 				});
	// 				setTimeout(function () {
	// 					const $headerHeight = $('header.header').css('height');
	// 					$blockContainer.css({
	// 						position: 'relative',
	// 						height: '100%',
	// 						marginTop: '-' + $headerHeight,
	// 					});
	// 					$halfBlockContainer.css({
	// 						position: 'relative',
	// 						height: '100%',
	// 						marginTop: '-' + $headerHeight,
	// 					});
	// 				}, 30);
	// 			}
	// 		}
	// 	}
	// 	videoResizer();
	// 	$(window).on('resize', function () {
	// 		videoResizer();
	// 	});
});
