import 'jquery-validation';
// Plugins
// import 'mmenu-js';
// import 'imagesloaded';
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import './modules/ajax-form';
// Modules
import './modules/animations';
import './modules/css';
import './modules/cssHelper';
import './modules/faqs';
import './modules/form';
import './modules/form-validation';
import './modules/image-gallery';
import './modules/masonry';
import './modules/mobile-menu';
import './modules/notification';
import './modules/pageHeader';
import './modules/svgIcon-sprite';
import './modules/videoBanner';

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    $('.shareBar__link.-hidden').removeClass('-hidden');
}
