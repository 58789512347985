import $ from 'jquery';
$(function ($) {
	setTimeout(function () {
		updateFooterHeight();
	}, 1000);

	$(window).on('resize', function () {
		updateFooterHeight();
	});

	function updateFooterHeight() {
		// placeholder height should always match footer height
		var footerHeight = $('.footer').css('height');
		$('.footerplaceholder').css('height', footerHeight);
	}

	const quickLinkEl = $('.fundHeroBanner__quickLinks--link');

	quickLinkEl.on('click', function () {
		quickLinkEl.removeClass('-selected');
		$(this).addClass('-selected');
	});

	// Hides the back to top button unless the user scrolls a certain distance
	const returnTopEl = $('#BackToTop');

	$(document).on('scroll', function () {
		if (window.scrollY < 900 && returnTopEl.hasClass('-revealed')) {
			returnTopEl.removeClass(' -revealed');
		} else if (
			window.scrollY > 900 &&
			returnTopEl.hasClass('-revealed') != true
		) {
			returnTopEl.addClass(' -revealed');
		}
	});

	const $quickLinksEl = $('.fundHeroBanner__quickLinks--link');
	const $quickLinksHomeEl = $('.fundHeroBanner__homeBtn');

	$(document).on('scroll', function () {
		if (window.scrollY < 50 && $quickLinksEl.hasClass('-shrunk')) {
			$quickLinksEl.removeClass('-shrunk');
			$quickLinksHomeEl.removeClass('-shrunk');
		} else if (
			window.scrollY > 50 &&
			$quickLinksEl.hasClass('-shrunk') != true
		) {
			$quickLinksEl.addClass('-shrunk');
			$quickLinksHomeEl.addClass('-shrunk');
		}
	});
});
