import $ from 'jquery';

$(($) => {
	// Close submenu when clicking outside elsewhere on the page
	$(document).click(function () {
		$('.header-nav__item, .main-nav__item').removeClass('-is-active');
	});

	// Display submenu
	$('.has-submenu').click(function (e) {
		const $li = $(this).closest('li');
		$('.header-nav__item, .main-nav__item')
			.not($li)
			.removeClass('-is-active');

		$li.toggleClass('-is-active');
		e.preventDefault();
		e.stopPropagation();
	});

	$('.subNav__link').on('focus', function () {
		// $('.subNav__link').parent
		$('.mainNav > .subNav > .subNav__link').css({
			opacity: 1,
			maxHeight: '1000px',
			visibility: 'visible',
			transition: 'all 250ms ease-in-out',
		});
	});

	//TODO move to search bar component
	function searchToggler() {
		$(this).toggleClass('-is-active');
		$('.searchBar').toggleClass('-is-active');
		if ($('.searchBar').hasClass('-is-active')) {
			$('.searchBar input').focus();
		}
	}

	$('.JS-searchToggle').on('click keyup', function (event) {
		if (event.keyCode === 13) {
			searchToggler();
		} else if (event.handleObj.type === 'click') {
			searchToggler();
		}
	});

	function externalToggler() {
		$('.JS-externalToggle').toggleClass('-is-active');
		$('.externalLinks').toggleClass('-is-active');
	}

	$('.JS-externalToggle').on('click keyup', function (event) {
		if (event.keyCode === 13) {
			externalToggler();
		} else if (event.handleObj.type === 'click') {
			externalToggler();
		}
	});
});
